<template>
  <header class="header">
    <nav class="header__nav">
      <h1 class="header__nav-title" title="Portfólio">Portfólio</h1>

      <div class="header__nav-menu">
        <a class="header__nav-menu-link" href="#about">Sobre mim</a>
        <a class="header__nav-menu-link" href="#projects">Projetos</a>
        <a class="header__nav-menu-link" href="#services">Serviços</a>
        <a class="header__nav-menu-link" href="#skills">Minhas skills</a>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'MyHeader'
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  margin: 0 auto;

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include largeMobile {
      justify-content: center;
    }

    &-title {
      color: $ligth-20;
      font-size: 40px;
      cursor: default;
    }

    &-menu {
      display: flex;
      gap: 48px;

      @include largeMobile {
        display: none;
      }

      &-link {
        text-decoration: none;
        color: $ligth-20;
        font-size: 16px;
        transition: all .4s ease;

        &:hover {
          transition: all .4s ease-in;
          color: $brand-color;
        }
      }
    }
  }
}
</style>
