<template>
  <section id="projects" class="section">
      <MySubtitle class="section-subtitle" subtitle="Projetos" />

      <div class="section__boxes">
        <div v-for="item in dataProjects" :key="item">
          <CustomBox>
            <a
              :href="item.link"
              target="_blank"
              class="section__boxes-link"
              rel="nofollow"
            >
              <div class="container">
                <img
                  :src="item.image"
                  :alt="item.title"
                  :title="item.title"
                >
              </div>
              <h3 class="title">{{ item.title }}</h3>
            </a>
            <div class="section__boxes-technologies">
              <span
                v-for="(stack, index) in item.stacks"
                :key="stack + index"
                :title="stack"
                class="badge"
              >
                {{ stack }}
              </span>
            </div>
          </CustomBox>
        </div>
      </div>
  </section>
</template>

<script>
import CustomBox from './ui/CustomBox.vue'
import MySubtitle from './MySubtitle.vue'

export default {
  name: 'MyProjects',

  components: {
    CustomBox,
    MySubtitle
  },

  data() {
    return {
      dataProjects: [
        {
          image: require('../assets/img/projects/me.png'),
          title: 'Mercado Eletrônico',
          link: 'https://me.com.br/',
          stacks: [ 'Javascript', 'Vue', 'Sass', 'Cypress' ]
        },
        {
          image: require('../assets/img/projects/mega-loterias.png'),
          title: 'Mega Loterias',
          link: 'https://www.megaloterias.com.br/',
          stacks: [ 'Javascript', 'Vue', 'Sass', 'Html5' ]
        },
        {
          image: require('../assets/img/projects/betsul.png'),
          title: 'Betsul',
          link: 'https://www.betsul.com/',
          stacks: [ 'Javascript', 'Vue', 'Sass', 'Js' ]
        },
        {
          image: require('../assets/img/projects/calculadora.png'),
          title: 'Calculadora',
          link: 'https://lucasheverton-calculator.vercel.app/',
          stacks: [ 'Javascript', 'Vue', 'Html5', 'Sass' ]
        },
        {
          image: require('../assets/img/projects/bikcraft.png'),
          title: 'Bikcraft',
          link: 'https://lucasheverton.github.io/bikcraft/',
          stacks: [ 'Javascript', 'Html5', 'Css3' ]
        },
        {
          image: require('../assets/img/projects/wildbeast.png'),
          title: 'Wildbeast',
          link: 'https://lucasheverton.github.io/wildbeast/',
          stacks: [ 'Html5', 'Css3' ]
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.section {
  width: 100%;
  display: grid;
  justify-items: center;

  &-subtitle {
    margin-bottom: 32px;
  }

  &__boxes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;

    @include laptop {
      grid-template-columns: repeat(2, 1fr);
     }

     @include tablet {
      grid-template-columns: 1fr;
     }

    &-link {
      text-decoration: none;

      :hover {
        text-decoration: underline;
      }

      .container {
        width: 288px;
        height: 144px;
        margin: 0 auto;
        background-color: $dark-10;
        border: 1px solid $dark-30;

        img {
          width: 288px;
          height: 144px;
          transition: all 0.4s ease-in;

          &:hover {
            transition: all 0.4s ease-in-out;
            transform: scale(1.05);
          }
        }
      }

      .title {
        margin-top: 8px;
        color: $ligth-10;
      }
    }


    &-technologies {
      display: inline-flex;
      gap: 8px;
      margin-top: 8px;
      color: $gray-10;

      .badge {
        background-color: $dark-30;
        border-radius: 16px;
        padding: 0 8px;
        font-weight: 500;
        color: $ligth-10;
        opacity: .9;
      }
    }
  }
}
</style>
